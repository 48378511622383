import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Svg from '../Svg';

import s from './BasicInput.module.scss';

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  isError: PropTypes.bool,
  errorText: PropTypes.node,
  errorId: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  onIconClick: PropTypes.func,
  labelClass: PropTypes.string,
  inputContainerClass: PropTypes.string,
  onBlur: PropTypes.func,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconClassName: PropTypes.string,
  labelDataAttribute: PropTypes.string,
  brainTreeHostedField: PropTypes.bool,
  piiData: PropTypes.bool,
  maxLength: PropTypes.number,
  iconColor: PropTypes.string,
  inputrefs: PropTypes.arrayOf(PropTypes.shape({ current: PropTypes.any })),
};

const BasicInput = ({
  type = 'text',
  isError = false,
  errorText = '',
  errorId = 'input-error',
  name = '',
  className = '',
  disabled = false,
  label = '',
  icon = '',
  onIconClick = () => {},
  onBlur = () => {},
  labelClass = 'field-label',
  inputContainerClass = '',
  iconWidth = '30px',
  iconHeight = '30px',
  iconClassName = '',
  labelDataAttribute = null,
  brainTreeHostedField = false,
  piiData = false,
  maxLength = null,
  iconColor = s.silverGradMed,
  inputrefs = [],
  ...props
}) => {
  const dynamicLabelAttribute = {};
  if (labelDataAttribute) dynamicLabelAttribute[labelDataAttribute] = 'true';
  if (brainTreeHostedField) {
    return (
      <div className={cx(s.basicInput, inputContainerClass, 'input-container')}>
        <span className={[labelClass, 'hosted-fields--label'].join(' ')} {...dynamicLabelAttribute}>
          {label}
        </span>
        <label className="label-txt" htmlFor={name}>
          <div
            {...props}
            className={cx('input-txt', 'hosted-field', className)}
            aria-label={label}
          />
        </label>
        {isError && errorText && (
          <span id={errorId} className="error-text">
            {errorText}
          </span>
        )}
      </div>
    );
  }
  return (
    <div className={cx(s.basicInput, inputContainerClass, 'input-container')}>
      <span className={labelClass} {...dynamicLabelAttribute}>
        {label}
      </span>
      <label className="label-txt" htmlFor={name}>
        <input
          {...props}
          type={type}
          className={cx('input-txt', className, { error: isError })}
          id={name}
          name={name}
          onBlur={onBlur}
          aria-label={label}
          disabled={disabled}
          data-hide={piiData}
          maxLength={maxLength}
          ref={(el) => {
            inputrefs.forEach((inputref) => {
              /* istanbul ignore next */
              if (!inputref) return;
              // eslint-disable-next-line no-param-reassign
              inputref.current = el;
            });
          }}
        />
        {icon && (
          <div
            className={['input-svg', iconClassName, icon, disabled ? 'disabled' : 'enabled'].join(
              ' ',
            )}
            role="button"
            aria-label={`${label} icon`}
            tabIndex="-1"
            onClick={onIconClick}
            onKeyPress={onIconClick}
            data-testid="toggle-password"
          >
            <Svg
              color={iconColor}
              icon={icon}
              width={iconWidth}
              height={iconHeight}
              viewBox="0 0 32 32"
            />
          </div>
        )}
      </label>
      {isError && errorText && (
        <span id={errorId} className="error-text">
          {errorText}
        </span>
      )}
    </div>
  );
};

BasicInput.propTypes = propTypes;
export default BasicInput;
